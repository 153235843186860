import React, { useState } from "react";
import { Button, Card, Flex, Form, Input, Spin, Alert, Typography, Checkbox } from "antd";
import { Link } from "react-router-dom";
import registerImage from "../assets/Register.png";
import useSignup from "../hooks/useSignup";

const Register = () => {
  const { loading, error, registerUser } = useSignup();
  const [isChecked, setIsChecked] = useState(false); // Track checkbox state

  const handleRegister = (values) => {
    if (isChecked) {
      registerUser(values);
    } else {
      // Show a message if the checkbox is not checked
      alert("You must accept the Privacy Policy and Terms and Conditions to proceed.");
    }
  };

  return (
    <Card className="form-container">
      <Flex gap="large" align="center">
        {/* form */}
        <Flex vertical flex={1}>
          <Typography.Title level={3} strong className="title">
            Create an account
          </Typography.Title>
          <Typography.Text type="secondary" strong className="slogan">
            Join for exclusive access!
          </Typography.Text>
          <Form layout="vertical" onFinish={handleRegister} autoComplete="off">
            <Form.Item
              label="Full Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your full name!",
                },
              ]}
            >
              <Input size="large" placeholder="Enter your Full Name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
                {
                  type: "email",
                  message: "The input is not valid Email!",
                },
              ]}
            >
              <Input size="large" placeholder="Enter your email" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password size="large" placeholder="Enter your password" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="passwordConfirm"
              rules={[
                {
                  required: true,
                  message: "Please input your Confirm Password!",
                },
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Re-enter your password"
              />
            </Form.Item>

            {/* Checkbox for Privacy Policy and Terms */}
            <Form.Item>
              <Checkbox
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              >
                I accept the{" "}
                <a
                  href="https://growwpaisa.com/privacy%20policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: "bold" }}
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  href="https://growwpaisa.com/Terms%20and%20conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: "bold" }}
                >
                  Terms and Conditions
                </a>
              </Checkbox>
            </Form.Item>

            {/* Error Message */}
            {error && (
              <Alert
                description={error}
                type="error"
                showIcon
                closable
                className="alert"
              />
            )}

            {/* Submit Button */}
            <Form.Item>
              <Button
                type={`${loading ? "" : "primary"}`}
                htmlType="submit"
                size="large"
                className="btn"
                disabled={!isChecked} // Disable the button until checkbox is checked
              >
                {loading ? <Spin /> : "Create Account"}
              </Button>
            </Form.Item>
            <Form.Item>
              <Link to="/login">
                <Button size="large" className="btn">
                  Sign In
                </Button>
              </Link>
            </Form.Item>
          </Form>
        </Flex>
        {/* Image */}
        <Flex flex={1}>
          <img src={registerImage} className="auth-image" />
        </Flex>
      </Flex>
    </Card>
  );
};

export default Register;





// import React from "react";
// import { Button, Card, Flex, Form, Input, Spin, Alert, Typography } from "antd";
// import { Link } from "react-router-dom";
// import registerImage from "../assets/Register.png";
// import useSignup from "../hooks/useSignup";

// const Register = () => {
//     const {loading, error, registerUser}= useSignup();
//   const handleRegister = (values) => {
//     registerUser(values);
//   };
//   return (
//     <Card className="form-container">
//       <Flex gap="large" align="center">
//         {/* form */}
//         <Flex vertical flex={1}>
//           <Typography.Title level={3} strong className="title">
//             Create an account
//           </Typography.Title>
//           <Typography.Text type="secondary" strong className="slogan">
//             Join for exclusive access!
//           </Typography.Text>
//           <Form layout="vertical" onFinish={handleRegister} autoComplete="off">
//             <Form.Item
//               label="Full Name"
//               name="name"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please input your full name!",
//                 },
//               ]}
//             >
//               <Input size="large" placeholder="Enter your Full Name" />
//             </Form.Item>
//             <Form.Item
//               label="Email"
//               name="email"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please input your Email!",
//                 },
//                 {
//                   type: "email",
//                   message: "The input is not valid Email!",
//                 },
//               ]}
//             >
//               <Input size="large" placeholder="Enter your email" />
//             </Form.Item>
//             <Form.Item
//               label="Password"
//               name="password"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please input your Password!",
//                 },
//               ]}
//             >
//               <Input.Password size="large" placeholder="Enter your password" />
//             </Form.Item>
//             <Form.Item
//               label="Password"
//               name="passwordConfirm"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please input your Confirm Password!",
//                 },
//               ]}
//             >
//               <Input.Password
//                 size="large"
//                 placeholder="Re-enter your password"
//               />
//             </Form.Item>

//             {error && (
//               <Alert
//                 description={error}
//                 type="error"
//                 showIcon
//                 closable
//                 className="alert"
//               />
//             )}

//             <Form.Item>
//               <Button
//                  type={`${loading ? '' : 'primary' }`}
//                 htmlType="submit"
//                 size="large"
//                 className="btn"
//               >
//                 {loading ? <Spin/> : 'Create Account'}
                
//               </Button>
//             </Form.Item>
//             <Form.Item>
//               <Link to="/login">
//                 <Button size="large" className="btn">
//                   Sign In
//                 </Button>
//               </Link>
//             </Form.Item>
//           </Form>
//         </Flex>
//         {/* Image */}
//         <Flex flex={1}>
//           <img src={registerImage} className="auth-image" />
//         </Flex>
//       </Flex>
//     </Card>
//   );
// };

// export default Register;
